import { createForm, setResponse, SubmitHandler, valiForm } from '@modular-forms/solid'
import { Component, createSignal } from 'solid-js'
import Button from '~/components/button/button'
import { ChapterStepPickerFormType, ChapterStepPickerProps, ChapterStepPickerSchema } from '~/components/chapter-step-picker/chapter-step-picker.interfaces'
import styles from '~/components/chapter-step-picker/chapter-step-picker.module.scss'
import formStyles from '~/components/forms/styles/simple-form.module.scss'
import { SelectEntry } from '~/components/fields/multi-select/multi-select.interfaces'
import Select from '~/components/fields/select/select'
import Modal from '~/components/modal/modal'
import { translations } from '~/translations'
import FormError from '~/components/form-error/form-error'
import { updateChapter } from '~/services/database/chapters'
import { setLoadingState } from '~/services/loading/loading'
import { hasCollaboratorForJob } from '~/services/database/collaborators'

const ChapterStepPicker: Component<ChapterStepPickerProps> = ({ cssClasses, chapter }) => {
  const [showModal, setShowModal] = createSignal(false)

  const openModal = () => {
    setShowModal(true)
  }

  const steps: SelectEntry[] = [
    {
      label: translations().editor.steps.translation,
      value: 'translation'
    },
    {
      label: translations().editor.steps.proofreading,
      value: 'proofreading'
    },
    {
      label: translations().editor.steps.typesetting,
      value: 'typesetting'
    },
    {
      label: translations().editor.steps.typeProofreading,
      value: 'typeProofreading'
    },
    {
      label: translations().editor.steps.validation,
      value: 'validation'
    },
    {
      label: translations().editor.steps.completed,
      value: 'complete'
    }
  ]

  const [form, { Form, Field }] = createForm<ChapterStepPickerFormType>({
    validate: valiForm(ChapterStepPickerSchema),
    initialValues: {
      step: chapter.progress
    }
  })

  const onSubmit: SubmitHandler<ChapterStepPickerFormType> = async (values) => {
    setLoadingState({
      loading: true
    })

    const canSwitch = await hasCollaboratorForJob(chapter.series_id, values.step)

    if (canSwitch){
      await updateChapter({
        id: chapter.id,
        progress: values.step
      })
      window.location.reload()
      setShowModal(false)
    }else{
      setResponse(form, {
        status: 'error',
        message: translations().chapters.setStep.errors.noCollaborator
      })
    }

    setLoadingState({
      loading: false
    })
  }

  // Labels
  const title = translations().chapters.setStep.title

  return (
    <div class={[cssClasses, styles.chapterStepPicker].join(' ')}>
      <Modal
        open={showModal()}
        setOpen={setShowModal}
        title={title}
      >
        <Form onSubmit={onSubmit} class={formStyles.form}>
          <Field name="step">
            {(field, props) => (
              <Select
                options={steps}
                form={form}
                {...field}
                {...props}
              />
            )}
          </Field>

          <FormError response={form.response} />

          <Button
            type='submit'
            style='lagoon'
            isLoading={form.submitting}
          >
            {translations().general.actions.edit}
          </Button>
        </Form>
      </Modal>

      <button
        class={styles.button}
        onClick={openModal}
      >
        {translations().general.actions.edit}
      </button>
    </div>
  )
}

export default ChapterStepPicker
