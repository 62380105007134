import { Tables } from '~/types/supabase'
import { InferOutput, object, string } from 'valibot'

export interface ChapterStepPickerProps {
  cssClasses: string
  chapter: Tables<'chapters'>
}

export const ChapterStepPickerSchema = object({
  step: string()
})

export type ChapterStepPickerFormType = InferOutput<typeof ChapterStepPickerSchema>

