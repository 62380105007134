import { Component } from 'solid-js'
import { ChapterProgressionProps } from '~/components/chapter-progression/chapter-progression.interfaces'
import styles from '~/components/chapter-progression/chapter-progression.module.scss'
import parentStyles from '~/components/works-chapter-entry/works-chapter-entry.module.scss'
import TranslationIcon from '~/assets/icons/translation.svg'
import ProofreadingIcon from '~/assets/icons/edit-alt.svg'
import TypesettingIcon from '~/assets/icons/caps.svg'
import TypeProofreadingIcon from '~/assets/icons/document-check.svg'
import ValidationIcon from '~/assets/icons/user-check.svg'
import { isWeekOld } from '~/helpers/dates'
import { translations } from '~/translations'
import ChapterStepPicker from '~/components/chapter-step-picker/chapter-step-picker'

const ChapterProgression: Component<ChapterProgressionProps> = ({ entry }) => {  
  const translationClasses = {
    [styles.step]: true,
    [styles.translation]: true,
    [styles.current]: entry.progress === 'translation',
    [styles.isBeforeCurrent]: entry.progress === 'proofreading'
  }
  const proofreadingClasses = {
    [styles.step]: true,
    [styles.proofreading]: true,
    [styles.current]: entry.progress === 'proofreading',
    [styles.isBeforeCurrent]: entry.progress === 'typesetting'
  }
  const typesettingClasses = {
    [styles.step]: true,
    [styles.typesetting]: true,
    [styles.isBeforeCurrent]: entry.progress === 'typeProofreading' || entry.progress === 'validation',
    [styles.current]: entry.progress === 'typesetting'
  }
  const typeProofreadingClasses = {
    [styles.step]: true,
    [styles.typeProofreading]: true,
    [styles.current]: entry.progress === 'typeProofreading'
  }
  const validationClasses = {
    [styles.step]: true,
    [styles.validation]: true,
    [styles.current]: entry.progress === 'validation'
  }

  if(entry.progress === 'completed'){
    const label = isWeekOld(entry.updated_at) ? translations().editor.steps.archived : translations().editor.steps.completed
    return (
      <div class={styles.chapterProgression}>
        <div class={parentStyles.archived}>
          <span class={styles.label}>{label}</span>
        </div>
      </div>
    )
  }

  const validationBlock = entry.progress === 'validation' ? (
    <div classList={validationClasses}>
      <ValidationIcon class={styles.icon} />
      <span class={styles.label}>
        {translations().editor.steps.validation}
      </span>
    </div>
  ) : (
    <div classList={typeProofreadingClasses}>
      <TypeProofreadingIcon class={styles.icon} />
      <span class={styles.label}>
        {translations().editor.steps.proofreading}
      </span>
    </div>
  )

  return (
    <div class={styles.chapterProgressionContainer}>
      <ChapterStepPicker
        cssClasses={styles.stepButton}
        chapter={entry}
      />

      <div class={styles.chapterProgression}>
        <div classList={translationClasses}>
          <TranslationIcon class={styles.icon} />
          <span class={styles.label}>
            {translations().editor.steps.translation}
          </span>
        </div>
        <div classList={proofreadingClasses}>
          <ProofreadingIcon class={styles.icon} />
          <span class={styles.label}>
            {translations().editor.steps.proofreading}
          </span>
        </div>
        <div classList={typesettingClasses}>
          <TypesettingIcon class={styles.icon} />
          <span class={styles.label}>
            {translations().editor.steps.typesetting}
          </span>
        </div>
        {validationBlock}
      </div>
    </div>
  )
}

export default ChapterProgression
